import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/woordenlijst/">Woordenlijst</a>
                    </li>
                    <li className="active parent-active last">
                      <a href="/veelgestelde-vragen-diarree/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li
                      id="uilist-hor nav-top nav-lang_nl-BE"
                      className="active"
                    >
                      <a href="/veelgestelde-vragen-diarree/moet-ik-diarree-behandelen/">
                        NL
                      </a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr">
                      <a href="/fr/faq-questions-diarrhee/la-diarrhee-que-faire/">
                        FR
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="0eefa2e2c0de6733a75fb3af0a219997448cf289"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/zoeken"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <h1 className="logo" style={{
                "margin-left": "3px"
              }}><a href="/">Imodium®</a>
                </h1>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/hoe-werkt-imodium-instant/">
                          Hoe werkt
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/imodium-behandelen-diarree/">
                          IMODIUM® <br />
                          Geneesmiddelen
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/feiten-over-diarree/">
                          Diarree - oorzaken
                          <br />
                          en behandeling
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/diarree-op-reis/">
                          Diarree
                          <br />
                          op reis
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/diarree-bij-kinderen/">
                          Diarree
                          <br />
                          bij kinderen
                        </a>
                      </li>                      
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/veelgestelde-vragen-diarree/"> FAQ </a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Tips bij diarree</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Tips bij diarree"
                  />
                </div>
                <p>
                  Als het gaat om de behandeling van de symptomen van diarree,
                  zijn er nog veel onzekerheden. Lees enkele tips.
                </p>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips bij diarree
                </a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
                <h4>Reisapotheek</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_reisen.jpeg"
                    width="170"
                    height="102"
                    alt="Reisapotheek"
                  />
                </div>
                <p>
                  Wat hoort thuis in de <strong>reisapotheek</strong>? Vind hier
                  alle praktische informatie.
                </p>
                <a href="/diarree-op-reis/reisapotheek/">Meer informatie</a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-96">
            <h1>Moet ik diarree behandelen?</h1>
            <p>
              Kortstondige aanvallen van diarree kan je zelf behandelen, tenzij
              je bijkomende symptomen krijgt zoals koorts, bloed of slijm in je
              stoelgang; in dat geval moet je een arts raadplegen. Symptomen van
              diarree kunnen worden verlicht door het gebruik van{" "}
              <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">
                IMODIUM® Capsules
              </a>
              ,{" "}
              <a href="/imodium-behandelen-diarree/imodium-instant/">
                IMODIUM® Instant orodispergeerbare tabletten
              </a>{" "}
              of{" "}
              <a href="/imodium-behandelen-diarree/imodium-duo/">
                IMODIUM® Duo tabletten
              </a>
              , die vaak verlichting kunnen bieden in slechts één dosis.
            </p>
            <p>
              IMODIUM® biedt u een uitgebreid gamma geneesmiddelen aan voor
              uiteenlopende situaties:
            </p>
            <p>Geneesmiddel zonder voorschrift verkrijgbaar in de apotheek.</p>
            <table>
              <thead>
                <tr>
                  <th>Geneesmiddel</th>
                  <th>Beschikbare verpakkingsformaten</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>IMODIUM® Instant&nbsp;orodispergeerbare tabletten</td>
                  <td>20, 60</td>
                </tr>
                <tr>
                  <td>IMODIUM® Duo tabletten</td>
                  <td>18</td>
                </tr>
                <tr>
                  <td>IMODIUM® Capsules</td>
                  <td>20, 60, 200</td>
                </tr>
              </tbody>
            </table>
            
            <a className="button radius" href="/veelgestelde-vragen-diarree/">
              meer veelgestelde vragen
            </a>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-nl.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
